var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('v-stepper',{staticClass:"elevation-0",attrs:{"vertical":"","non-linear":"","value":_vm.currentStep}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [_c('v-stepper-step',{attrs:{"step":_vm.steps.contrato.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.contrato.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.contrato.subtitle))])]),_c('v-stepper-content',{attrs:{"step":_vm.steps.contrato.id}},[_c('ValidationProvider',{attrs:{"name":"objeto","rules":_vm.formRules.objeto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Objeto del contrato","error-messages":errors},model:{value:(_vm.form.objeto),callback:function ($$v) {_vm.$set(_vm.form, "objeto", $$v)},expression:"form.objeto"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"tcontrato","rules":_vm.formRules.tcontrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tcontratos,"item-text":"descripcion","label":"Tipo de contrato","clearable":"","error-messages":errors,"return-object":"","no-filter":""},on:{"change":_vm.tcontratoSelected},model:{value:(_vm.form.tcontrato),callback:function ($$v) {_vm.$set(_vm.form, "tcontrato", $$v)},expression:"form.tcontrato"}})]}}],null,true)})],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [_c('v-stepper-step',{attrs:{"step":_vm.steps.inicioExtraordinario.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.inicioExtraordinario.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.inicioExtraordinario.subtitle))])]),_c('v-stepper-content',{attrs:{"step":_vm.steps.inicioExtraordinario.id}},[_c('ValidationProvider',{attrs:{"name":"inicio_extraordinario"}},[_c('v-checkbox',{attrs:{"label":"Inicio con carácter urgente"},on:{"change":_vm.inicioExtraordinarioSelected},model:{value:(_vm.form.inicio_extraordinario),callback:function ($$v) {_vm.$set(_vm.form, "inicio_extraordinario", $$v)},expression:"form.inicio_extraordinario"}})],1),_c('ValidationProvider',{attrs:{"name":"dur_fexcepcional","rules":_vm.formRules.dur_fexcepcional},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-time-picker',{attrs:{"title":"Fecha y hora","disabled":!_vm.form.inicio_extraordinario,"error-messages":errors},model:{value:(_vm.form.dur_fexcepcional),callback:function ($$v) {_vm.$set(_vm.form, "dur_fexcepcional", $$v)},expression:"form.dur_fexcepcional"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"dur_causa","rules":_vm.formRules.dur_causa},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Causa","disabled":!_vm.form.inicio_extraordinario,"error-messages":errors},model:{value:(_vm.form.dur_causa),callback:function ($$v) {_vm.$set(_vm.form, "dur_causa", $$v)},expression:"form.dur_causa"}})]}}],null,true)}),_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Entidad comunicada","disabled":!_vm.form.inicio_extraordinario},model:{value:(_vm.form.dur_entidad_comunicada),callback:function ($$v) {_vm.$set(_vm.form, "dur_entidad_comunicada", $$v)},expression:"form.dur_entidad_comunicada"}})],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [_c('v-stepper-step',{attrs:{"step":_vm.steps.masDatos.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.masDatos.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.masDatos.subtitle))])]),_c('v-stepper-content',{attrs:{"step":_vm.steps.masDatos.id}},[_c('ValidationProvider',{attrs:{"name":"fcontrato","rules":_vm.formRules.fcontrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-picker',{attrs:{"title":"Fecha de contrato","error-messages":errors},model:{value:(_vm.form.fcontrato),callback:function ($$v) {_vm.$set(_vm.form, "fcontrato", $$v)},expression:"form.fcontrato"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"idserie_contrato","rules":_vm.formRules.idserie_contrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.seriesContrato,"item-value":"idserie_contrato","item-text":"descripcorta","label":"Serie de contratos","clearable":"","error-messages":errors},model:{value:(_vm.form.idserie_contrato),callback:function ($$v) {_vm.$set(_vm.form, "idserie_contrato", $$v)},expression:"form.idserie_contrato"}})]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }