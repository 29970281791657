import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idempresa, iddelegacion, fecha, idtactuacionTsistema) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectTcontrato', method: 'tcontrato.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterSerie = new APIFilter()
      .addGT('estado', 0)
      .addExact('idempresa', idempresa)
    apiCalls.push({ name: 'selectSerieContrato', method: 'serieContrato.select', params: { filter: apiFilterSerie } })
    apiCalls.push({
      name: 'selectSerieContratoDefecto',
      method: 'serieContrato.serieDefecto',
      params: { idempresa, iddelegacion, fecha },
    })
    const apiFilterTactuacionTsistema = new APIFilter()
      .addExact('idtactuacion_tsistema', idtactuacionTsistema)
    apiCalls.push({ name: 'selectTactuacionTsistema', method: 'tactuacionTsistema.select', params: { filter: apiFilterTactuacionTsistema } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectTcontrato (Vue, idtcontrato) {
    const apiFilter = new APIFilter()
      .addExact('idtcontrato', idtcontrato)
    const resp = await Vue.$api.call('tcontrato.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
