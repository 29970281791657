<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.contrato.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.contrato.title }}
          <small>{{ steps.contrato.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.contrato.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="objeto"
            :rules="formRules.objeto"
          >
            <v-text-field
              v-model="form.objeto"
              clearable
              flat
              label="Objeto del contrato"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="tcontrato"
            :rules="formRules.tcontrato"
          >
            <b10-autocomplete
              v-model="form.tcontrato"
              :items="tcontratos"
              item-text="descripcion"
              label="Tipo de contrato"
              clearable
              :error-messages="errors"
              return-object
              no-filter
              @change="tcontratoSelected"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.inicioExtraordinario.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.inicioExtraordinario.title }}
          <small>{{ steps.inicioExtraordinario.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.inicioExtraordinario.id"
        >
          <ValidationProvider
            name="inicio_extraordinario"
          >
            <v-checkbox
              v-model="form.inicio_extraordinario"
              label="Inicio con carácter urgente"
              @change="inicioExtraordinarioSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="dur_fexcepcional"
            :rules="formRules.dur_fexcepcional"
          >
            <b10-date-time-picker
              v-model="form.dur_fexcepcional"
              title="Fecha y hora"
              :disabled="!form.inicio_extraordinario"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="dur_causa"
            :rules="formRules.dur_causa"
          >
            <v-text-field
              v-model="form.dur_causa"
              clearable
              flat
              label="Causa"
              :disabled="!form.inicio_extraordinario"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-text-field
            v-model="form.dur_entidad_comunicada"
            clearable
            flat
            label="Entidad comunicada"
            :disabled="!form.inicio_extraordinario"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.masDatos.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.masDatos.title }}
          <small>{{ steps.masDatos.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.masDatos.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="fcontrato"
            :rules="formRules.fcontrato"
          >
            <b10-date-picker
              v-model="form.fcontrato"
              title="Fecha de contrato"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="idserie_contrato"
            :rules="formRules.idserie_contrato"
          >
            <b10-autocomplete
              v-model="form.idserie_contrato"
              :items="seriesContrato"
              item-value="idserie_contrato"
              item-text="descripcorta"
              label="Serie de contratos"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './PresupuestocliContratoAddFormData'
import { currentDate } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {
    idempresa: {
      type: Number,
      default: null,
    },
    iddelegacion: {
      type: Number,
      default: null,
    },
    idtactuacionTsistema: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      form: {
        // contrato
        objeto: null,
        tcontrato: null,
        // inicio extraordinario
        inicio_extraordinario: false,
        dur_entidad_comunicada: null,
        dur_fexcepcional: null,
        dur_causa: null,
        // más datos
        fcontrato: currentDate(),
        idserie_contrato: null,
      },
      formRules: {
        objeto: { required: true },
        tcontrato: { required: true },
        dur_fexcepcional: { required_if: { target: 'inicio_extraordinario', value: true } },
        dur_causa: { required_if: { target: 'inicio_extraordinario', value: true } },
        fcontrato: { required: true },
        idserie_contrato: { required: true },
      },
      steps: {
        contrato: {
          id: 1,
          title: 'Contrato',
          subtitle: 'Datos principales del contrato',
        },
        inicioExtraordinario: {
          id: 2,
          title: 'Inicio extraordinario',
          subtitle: 'Datos de inicio extraordinario del contrato',
        },
        masDatos: {
          id: 3,
          title: 'Más datos...',
          subtitle: 'Otros datos para crear el contrato',
        },
      },
      currentStep: 1,
      seriesContrato: [],
      tcontratos: [],
    }
  },
  async created () {
    const resp = await Data.selectLookups(this, this.idempresa, this.iddelegacion, this.form.fcontrato, this.idtactuacionTsistema)
    this.seriesContrato = resp.data.selectSerieContrato.result.dataset
    this.tcontratos = resp.data.selectTcontrato.result.dataset
    if (resp.data.selectSerieContratoDefecto.result) {
      this.$set(this.form, 'idserie_contrato', resp.data.selectSerieContratoDefecto.result.dataset[0].idserie_contrato)
    }
    const tactuacionTsistema = resp.data.selectTactuacionTsistema.result.dataset[0]
    if (tactuacionTsistema.idtcontrato) {
      this.$set(this.form, 'tcontrato', await Data.selectTcontrato(this, tactuacionTsistema.idtcontrato))
      // forzar evento
      this.tcontratoSelected(this.form.tcontrato)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    tcontratoSelected (value) {
      if (value) {
        this.$set(this.form, 'objeto', value.objeto)
      } else {
        this.$set(this.form, 'objeto', null)
      }
    },
    inicioExtraordinarioSelected (value) {
      if (!value) {
        this.$set(this.form, 'dur_fexcepcional', null)
        this.$set(this.form, 'dur_causa', null)
        this.$set(this.form, 'dur_entidad_comunicada', null)
      }
    },
  }
}
</script>

<style>
.v-stepper__label {
  text-shadow: none !important;
}
.number-input >>> input {
  text-align: right;
}
</style>
